import React, { useMemo } from 'react'
import { css } from 'glamor'
import { Button } from '../../atoms'

/**
 * Tab interface
 * @typedef {Object} Tab
 * @property {string} id - Used to identify active tab
 * @property {string} label - Text that will show up in the tab
 */

 const tabsStyles = css({

   margin: 0,
   padding: 0,
   boxSizing: 'border-box',
   overflowY: 'hidden',
   overflowX: 'auto',
   height: '100%',
   whiteSpace: 'nowrap',
   background: 'transparent',

   '& ul': {
     margin: '0',
     padding: '0',
     display: 'flex',
     justifyContent: 'flex-start',
     alignItems: 'stretch',
     flexWrap: 'no-wrap',
   },

   '& li': {
     listStyle: 'none'
   }

 })

export const Tabs = ({
  as: Element = 'nav',
  tabs = [],
  activeTabId,
  variant = 'tab',
  onTabClick = () => {},
  ...props
}) => (
  <Element {...props} {...tabsStyles}>
    <ul>
      {
        tabs.map(tab => (
          <li key={tab.id}>
            <Button
              variant={tab.id === activeTabId ? `${variant}-active` : variant}
              onClick={e => onTabClick(e, tab)}
              {...tab}
            >
              {tab.label}
            </Button>
          </li>
        ))
      }
    </ul>
  </Element>
)
