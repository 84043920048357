import React, { useMemo } from 'react'
import { css } from 'glamor'

export const MaxWidth = ({
  as: Component = 'div',
  variant,
  ...props
}) => {

  const contentMaxWidthStyles = useMemo(() => {
    return css({
      '--max-width': `var(--content-max-width-${variant}, 100%)`,

      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      maxWidth: 'var(--max-width)',
    })
  }, [variant])

  return <Component { ...contentMaxWidthStyles } {...props}/>
}
