import React from 'react'
import { css } from 'glamor'
import { Button } from '../../atoms'

const tagsStyles = css({
  listStyle: 'none',
  userSelect: 'none',
  padding: '0',
  margin: '0'
})

const tagStyles = css({
  display: 'inline-block',
  margin: 'var(--space-xs)',
})

export const Tag = ({
  onSelect = () => {},
  active,
  ...props
}) =>
  <li {...tagStyles} >
    <Button
      variant={active ? 'tag-active' : 'tag'}
      onClick={() => onSelect(props.children)}
      {...props}
    />
  </li>

const alphabeticalAscending = (a, b) => {
  return a.localeCompare(b)
}

export const Tags = ({
  tags = [],
  activeTags = [],
  onSelect,
  ...props
}) => {
  const sortedTags = [...tags].sort(alphabeticalAscending)

  return (
    <ul {...tagsStyles} {...props}>
      {sortedTags.map(tag => (
        <Tag
          key={tag}
          active={activeTags.includes(tag)}
          onSelect={onSelect}
        >{tag}
        </Tag>
      ))}
    </ul>
  )
}
