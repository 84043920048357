import React from 'react'
import { Surface } from '../surface'
import { MaxWidth } from '../max-width'

export const ContentSection = ({
  surface,
  maxWidth,
  surfaceAs = 'section',
  maxWidthAs = 'div',
  children,
  ...props
}) => (
  <Surface variant={surface} as={surfaceAs} {...props}>
    <MaxWidth variant={maxWidth} as={maxWidthAs} style={{ height: '100%' }}>
      {children}
    </MaxWidth>
  </Surface>
)
