import React from 'react'
import { css } from 'glamor'
import {
  Icon,
  Button,
  Text,
  Title,
  Card,
} from '../../atoms'
import { Tags } from '../../molecules'

const styles = css({
  minHeight: '0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 'var(--space-l)',
  scrollbarWidth: 'none',
  overflowY: 'auto',

  '::-webkit-scrollbar': { display: 'none' },

  '& .titles': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 'var(--space-s)',
    textAlign: 'center'
  },

  '& .tags': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 'var(--space-m)',
    maxWidth: '600px'
  },

  '& img': {
    borderRadius: '50%',
    width: '50%',
    maxWidth: '200px',
    height: 'auto',
    margin: '0 auto',
  }

})

export const ProfileCard = ({
  name = '',
  role = '',
  image = '',
  links = [],
  summary = '',
  tags = [],
  activeTags = [],
  cardVariant,
  translations = {},
  onTagClick = () => {},
  ...props
}) => (
  <Card data-scroll variant={cardVariant} {...styles} {...props}>

    <img src={image} alt={name} />

    <div className="titles">
      <Title as="h1" variant="primary">{name}</Title>
      <Title as="h2" variant="lead">{role}</Title>
    </div>

    <div>
      {
        links.map(x => (
          <Button
            {...x}
            variant={x.variant || 'link'}
            style={{ paddingLeft: '0' }}
            key={x.label}
          >
            {x.icon && <Icon name={x.icon}/>}
            {x.label}
          </Button>
        ))
      }
    </div>

    <div>
      {
        summary
          .split('\n\n')
          .map(paragraph => (
            <Text key={paragraph} style={{ maxWidth: '600px', textAlign: 'left' }}>
              {paragraph}
            </Text>
          ))
      }
    </div>

    {
      tags.length && (
        <div className="tags">
          <Title as="h3" variant="secondary">{translations.tags}</Title>
          <Tags
            tags={tags.sort((a, b) => a.localeCompare(b))}
            activeTags={activeTags}
            onSelect={onTagClick}
            style={{ textAlign: 'center' }}
          />
        </div>
      )
    }

  </Card>
)
