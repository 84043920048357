import React from 'react'
import { Outlet } from 'react-router-dom'
import {
  Tabs,
  Footer,
  Header,
  ContentSection,
  Title,
  Text
} from 'design-system'

export const StandardLayout = ({
  logo,
  headerActions,
  headerAccentBorder = true,
  navigation,
  headerMaxWidth = 'medium',
  footerMaxWidth = 'medium',
  footerColumns,
  copyright,
}) => {
  return (
    <>

      { headerAccentBorder && (
          <ContentSection
            id="accent-border"
            style={{
              height: '4px',
              position: 'sticky',
              zIndex: '10',
              top: '0',
              background: 'var(--separator-accent)'
            }}
          />
      )}

      { (logo || headerActions) && (
          <ContentSection
            id="header"
            surface="primary"
            maxWidth={headerMaxWidth}
            style={{
              padding: 'var(--space-m) var(--space-l)',
              borderBottom: '1px solid var(--separator-primary)',
            }}
          >
            <Header logo={logo} actions={headerActions} />
          </ContentSection>
      )}

      { navigation && (
          <ContentSection
            id="navigation"
            surface="primary"
            maxWidth={headerMaxWidth}
            style={{
              borderBottom: '1px solid var(--separator-primary)',
              height: '55px',
              position: 'sticky',
              top: '2px',
              width: '100%',
              boxShadow: '2px 2px 4px hsla(0, 0%, 0%, .1)',
              zIndex: '10'
            }}
          >
            <Tabs tabs={navigation} style={{ height: '100%'}}/>
          </ContentSection>
      )}

      <Outlet />

      { footerColumns && (
          <ContentSection
            id="footer"
            surface="primary"
            surfaceAs="footer"
            maxWidth={footerMaxWidth}
            style={{
              borderBottom: '1px solid var(--separator-primary)',
              padding: 'var(--space-l) var(--space-m)'
            }}
          >
            <Footer columns={footerColumns} />
          </ContentSection>
      )}


      { logo && (
          <ContentSection
            id="footer-section-logo"
            surface="primary"
            maxWidth={footerMaxWidth}
            style={{
              padding: 'var(--space-m)',
              borderBottom: '1px solid var(--separator-primary)',
            }}
          >
            <div style={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              { typeof logo !== 'string'
                  ? logo
                  : (
                    <Title variant="logo">
                      {logo}
                    </Title>
                  )
              }
            </div>
          </ContentSection>
      )}

      { copyright && (
          <ContentSection
            id="footer-section-copyright"
            surface="primary"
            maxWidth={footerMaxWidth}
            style={{
              padding: 'var(--space-m)',
              textAlign: 'center',
              borderBottom: '8px solid var(--separator-accent)',
            }}
          >
            <Text variant="small" >
              {copyright}
            </Text>
          </ContentSection>
      )}

    </>
  )
}
