import React, { useMemo, useEffect, useState } from 'react'
import { css } from 'glamor'

const PerceivedMiddle = css({
  display: 'grid',
  gridTemplateRows: '1fr min-content 2fr',
  gridTemplateColumns: '1fr min-content 1fr',
  gridTemplateAreas: `". . ." ". content ." ". . ."`,

  '& [slot="content"]': { gridArea: 'content' }
})

const VerticalTop = css({
  display: 'grid',
  gridTemplateRows: '.05fr min-content 1fr',
  gridTemplateColumns: '1fr min-content 1fr',
  gridTemplateAreas: `". . ." ". content ." ". . ."`,

  '& [slot="content"]': { gridArea: 'content' }
})

const sidebar = css({
  display: 'grid',
  gridTemplateRows: '1fr',
  gridTemplateColumns: '300px 1fr',
  gridTemplateAreas: `"sidebar content"`,

  '& [slot="sidebar"]': { gridArea: 'sidebar' },
  '& [slot="content"]': { gridArea: 'content' }
})

const layouts = {
  PerceivedMiddle,
  VerticalTop,
  sidebar
}

export const Layout = ({
  variant,
  as: Container = 'div',
  ...props
}) => {
  const layoutStyles = useMemo(() => layouts[variant] || {}, [variant])
  return <Container {...props} {...layoutStyles}/>
}
