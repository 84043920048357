export const Standard = {

  space: {
    xxs: '2px',
    xs: '4px',
    s: '8px',
    m: '16px',
    l: '32px',
    xl: '64px',
    xxl: '128px',
  },

  'content-max-width': {
    'extra-small': '600px',
    small: '900px',
    medium: '1200px',
    large: '1900px',
    full: '100%',
  }

}
