import React, { useRef } from 'react'
import { css } from 'glamor'
import { useClipboardCopy } from '../../hooks'
import { Title, Text, Button, Icon } from '../../atoms'

const styles = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '150px 32px'
})

export const EmailContact = ({
  email,
  buttonLabel = 'Copy email to clipboard'
}) => {
  const [canCopy, copy] = useClipboardCopy()

  return (
    <div {...styles} >

      <div style={{ textAlign: 'center'}}>
        <a href={`mailto:${email}`} style={{ textDecoration: 'none' }}>
          <Title variant="display">
            {email}
          </Title>
        </a>
        <div className="text-center" style={{ marginTop: '48px' }}>
          { canCopy && (
            <Button variant="cta" onClick={() => copy(email)}>
              {buttonLabel}
            </Button>
          )}
        </div>
      </div>

    </div>
  )
}
