import React from 'react'
import { css } from 'glamor'
import {
  Title,
  Text,
  ContentSection,
  Image,
  Stack
} from '../../atoms'
import { Guide, Footer, Hero } from '../../organisms'

export const LandingPage = ({
  hero = {},
  services,
  about,
  contact = []
}) => (
  <>
    <ContentSection
      id="hero"
      surface="decorated"
      maxWidth="small"
      style={{
        borderBottom: '1px solid var(--separator-primary)'
      }}
    >
      <Hero {...hero}/>
    </ContentSection>

    {
      // <ContentSection
      //   id="social-proof"
      //   surface="primary"
      //   maxWidth="small"
      //   style={{
      //     padding: 'var(--space-xxl) var(--space-m)',
      //     borderBottom: '1px solid var(--separator-primary)'
      //   }}
      // >
      //
      //   <Title variant="primary" style={{ textAlign: 'center', marginBottom: 'var(--space-l)'}}>
      //     Update content easily through the UI
      //   </Title>
      //
      //   <Text style={{ textAlign: 'center' }}>
      //     With an simple and intuative UI, updating content is super easy.
      //     The content is organised in a familiar directory structure.
      //     You can also invite collaborators and manage users.
      //   </Text>
      //
      // </ContentSection>
    }

    {
      services && (
        <ContentSection
          id="services"
          surface="primary"
          maxWidth="large"
          style={{
            borderBottom: '1px solid var(--separator-primary)'
          }}
        >

          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-around'
          }}>

            {
              services.map(({ title, text }) => (
                <div key={title} style={{
                  padding: 'var(--space-xl) var(--space-m)',
                  textAlign: 'center'
                }}>
                  <div>
                    <Title variant="tertiary" style={{ marginBottom: 'var(--space-m)' }}>
                      {title}
                    </Title>
                    <Text style={{ maxWidth: '600px' }}>
                      {text}
                    </Text>
                  </div>
                </div>
              ))
            }

          </div>
        </ContentSection>
      )
    }

    {
      about && (
        <ContentSection
          id="about"
          surface="primary"
          maxWidth={about.maxWidth || 'medium'}
          style={{
            padding: 'var(--space-xl) var(--space-m)',
            borderBottom: '1px solid var(--separator-primary)'
          }}
        >
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: 'space-around',
            margin: '0 auto',
            gap: `var(--space-${about.gap || 'm'})`,
            padding: 'var(--space-s)',
          }}>
            {
              about.columns.map(({ name, role, imgSrc, text }) => (
                <div key={name} style={{
                  flexBasis: `calc(calc(100% / ${about.columns.length}) - var(--space-${about.gap}))`,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}>
                  <Image
                    key={name}
                    src={imgSrc}
                    style={{
                      borderRadius: '50%',
                      width: '100%',
                      maxWidth: '200px',
                      height: 'auto',
                      margin: '0 auto',
                      marginBottom: 'var(--space-l)'
                    }}
                  />

                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 'var(--space-s)',
                    alignItems: 'center',
                    marginBottom: 'var(--space-l)'
                  }}>
                    <Title as="h1" variant="primary">{name}</Title>
                    <Title as="h2" variant="lead">{role}</Title>
                  </div>

                  <Text style={{ textAlign: 'center', maxWidth: '600px', margin: '0 auto' }}>
                    {text}
                  </Text>
                </div>
              ))
            }
          </div>
        </ContentSection>
      )
    }

    {
      contact && (
        <ContentSection
          id="contact"
          surface={contact.surface || 'primary'}
          maxWidth={contact.maxWidth || 'small'}
          style={{
            padding: 'var(--space-l) var(--space-m)',
            borderBottom: '1px solid var(--separator-primary)'
          }}
        >
          <Footer columns={contact.columns} />
        </ContentSection>
      )
    }

  </>
)
