import React from 'react'
import { redirect } from 'react-router-dom'
import { App } from 'connected-components'
import { Tepit } from 'themes'
import { LandingPage } from 'design-system'
import { StandardLayout } from 'design-system-extras'
import { Swedish as SE } from './data.js'

const routes = [
  {
    path: '/',
    element: <StandardLayout { ...SE.StandardLayout } />,
    children: [
      {
        index: true,
        loader: () => redirect('/sv'),
      },
      {
        path: '/:languageCode',
        element: <LandingPage { ...SE.LandingPage } />
      }
    ]
  }
]

export default () => (
  <App
    workspaceId="x86lBDscU2SeEUxHPFxKh"
    theme={Tepit}
    routes={routes}
  />
)
