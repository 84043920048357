import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useRef
} from 'react'

const getCssVars = (styleObject, cssVars = [], _previousPath = '') => {

  return Object.entries(styleObject).map(([key, value]) => {

    if (typeof value === 'object') {
      return !!_previousPath
        ? getCssVars(value, cssVars, `${_previousPath}-${key}`)
        : getCssVars(value, cssVars, `--${key}`)
    } else if (!!_previousPath) {
      return { [`${_previousPath}-${key}`]: value }
    } else {
      return { [`--${key}`]: value }
    }
  }).flat()

}

export const addCssProperties = (element, styleObject) => {
  getCssVars(styleObject)
    .flatMap(x => Object.entries(x))
    .forEach(([name, value]) => {
      element.style.setProperty(name, value)
  })
}

export const removeCssProperties = (element, styleObject) => {
  getCssVars(styleObject)
    .flatMap(x => Object.entries(x))
    .forEach(([name, value]) => {
      element.style.removeProperty(name)
  })
}

const ThemeContext = createContext({})

export const Theme = ({
  theme,
  themes = {},
  elementSelector = ':root',
  ...props
}) => {
  const _themes = typeof theme === 'object' ? { default: theme } : themes
  const previousTheme = useRef()
  const [activeTheme, setTheme] = useState(() => typeof theme === 'object' ? 'default' : theme)

  useEffect(() => {
    if (!activeTheme) return

    const element = document.querySelector(elementSelector)
    if (!element) return

    const themeValues = _themes[activeTheme]
    if (!themeValues) return

    !!previousTheme?.current && removeCssProperties(element, previousTheme.current)
    previousTheme.current = themeValues
    addCssProperties(element, themeValues)
  }, [activeTheme, _themes, elementSelector])

  return <ThemeContext.Provider
    value={{
      activeTheme,
      themes: Object.keys(_themes),
      setTheme,
    }}
    {...props}
  />
}

export const useTheme = () => useContext(ThemeContext)
