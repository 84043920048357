import React, { useEffect } from 'react'
import { css } from 'glamor'
import { Button, Text, Title, Dropdown, ContextMenu } from '../../atoms'
import { Tags } from '../../molecules'
import { Tabs, ProfileCard, ResumeExperience } from '../../organisms'
import { useTheme } from '../..//utility'
import { useResume } from './useResume'

const styles = css({
  '--padding': 'var(--resume-padding, 0)',
  '--background': 'var(--resume-background, transparent)',
  '--header-background': 'var(--resume-header-background)',
  '--header-padding': 'var(--resume-header-padding, 8px 24px 8px 0)',
  '--header-border-bottom': 'var(--resume-header-border-bottom, none)',
  '--header-justify-content': 'var(--resume-header-justify-content, center)',
  '--filter-padding': 'var(--resume-filter-padding, var(--space-s) var(--space-m))',
  '--content-background': 'var(--resume-content-background, transparent)',

  background: 'var(--background)',
  padding: 'var(--padding)',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'fit-content fit-content fit-content',
  gridTemplateAreas: `
    "profile-summary"
    "actions"
    "content"
  `,

  '& [data-slot="actions"]': {
    minWidth: '0',
    boxSizing: 'border-box',
    gridArea: 'actions',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 'var(--header-padding)',
    background: 'var(--header-background)',
    borderBottom: 'var(--header-border-bottom)',
    '::-webkit-scrollbar': { display: 'none' }
  },

  '& .secondary-actions': {
    display: 'none',
    gap: 'var(--space-s)'
  },

  '& [data-slot="profile-summary"]': {
    gridArea: 'profile-summary' ,
    zIndex: '1',
  },

  '& [data-slot="content"]': {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gridArea: 'content',

    padding: 'var(--space-l) var(--space-m)',
    margin: 'var(--content-margin)',
    gap: 'var(--space-xl)',
    background: 'var(--content-background)',
  },

  '& [data-animation="fade-in"]': {
    opacity: '0',
    transitionProperty: 'opacity, transform',
    transitionDuration: '1s, 1s',
    transitionTimingFunction: 'ease-in-out',
    transform: 'translateZ(0)',
  },

  '& [data-scroll]': {
    scrollbarWidth: 'none',
    overflowY: 'auto',
    '::-webkit-scrollbar': { display: 'none' }
  },

  '&[data-status="Success"] [data-animation="fade-in"]': {
    opacity: '1'
  },

  '& .content-section': {
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--space-l)',
    width: '100%',
    maxWidth: '700px',
  },

  '@media (min-width: 1200px)': {
      height: '100vh',
      gridTemplateColumns: '400px 1fr',
      gridTemplateRows: 'min-content 1fr',
      gridTemplateAreas: `
        "profile-summary actions"
        "profile-summary content"
    `,

    '& [data-slot="actions"]': {
      justifyContent: 'space-between'
    },

    '& .secondary-actions': {
      display: 'flex'
    },

  },

  '@media (min-width: 1900px)': {
    '& [data-slot="content"]': {
      gridColumnStart: 'profile-summary',
      gridColumnEnd: 'content',
      gridRowStart: 'content',
      gridRowEnd: 'content',
    },
  },

})

export const Resume = ({
  status,
  profile,
  experiences,
  profileCardVariant,
  translations,
  actions = []
}) => {

  const {
    work,
    education,
    project,
    other,
    tags,
    categories,
    activeTags,
    toggleActiveTag,
    activeExperienceType,
    toggleActiveExperienceType,
  } = useResume(experiences)

  const tabs = [
    {
      id: 'All',
      label: translations.all,
    },
    {
      id: 'Work',
      label: translations.work,
    },
    {
      id: 'Project',
      label: translations.projects,
    },
    {
      id: 'Education',
      label: translations.education,
    },
    {
      id: 'Other',
      label: translations.other,
    }
  ].filter(x => x.id === 'All'
    ? x
    : categories.includes(x.id)
  )

  const sections = [
    {
      title: translations.work,
      experiences: work
    },
    {
      title: translations.projects,
      experiences: project
    },
    {
      title: translations.education,
      experiences: education
    },
    {
      title: translations.other,
      experiences: other
    }
  ]

  const print = () => {
    window.print()
  }

  return (
    <div {...styles} data-status={status}>

      <ProfileCard
        data-scroll
        data-slot="profile-summary"
        activeTags={activeTags}
        onTagClick={toggleActiveTag}
        translations={translations}
        cardVariant={profileCardVariant}
        {...profile}
      />

      <header className="actions" data-slot="actions">

        <Tabs
          tabs={tabs}
          activeTabId={activeExperienceType || 'All'}
          onTabClick={(_, tab) => toggleActiveExperienceType(tab.id)}
          style={{
            borderBottom: !!tags.length ? 'var(--header-border-bottom)' : undefined
          }}
        />

        <div className="secondary-actions">
        {
          actions.map(({ label, contextMenu, ...buttonProps }) => (
            !contextMenu
              ? (
                <Button {...buttonProps}>
                  {label}
                </Button>
              ) : (
                <Dropdown trigger={(
                  <Button {...buttonProps}>
                    {label}
                  </Button>
                )}>
                  <ContextMenu>
                    { contextMenu.map(menuItem => (
                      <ContextMenu.Item {...menuItem}>
                        {menuItem.label}
                      </ContextMenu.Item>
                    ))}
                  </ContextMenu>

                </Dropdown>
              )

          ))
        }
        </div>


        {
          !!tags.length && (
            <Tags
              tags={tags}
              activeTags={activeTags}
              onSelect={toggleActiveTag}
              style={{
                padding: 'var(--filter-padding)',
                display: 'flex',
                // overflowY: 'auto'
              }}
            />
          )
        }
      </header>


      <main className="content" data-slot="content" data-scroll>

        {
          sections
            .filter(x => !!x.experiences.length)
            .map(({ title, experiences }) => (
              <section className="content-section" key={title}>
                <Title variant="primary" style={{ textAlign: 'center' }}>{title}</Title>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--space-l)'}}>
                  {
                    experiences.map(experience =>
                      <ResumeExperience
                        key={experience.id || experience.title}
                        title={experience.title}
                        subTitle={experience.subTitle}
                        date={experience.date}
                        description={experience.description}
                        website={experience.website}
                        github={experience.github}
                        tags={experience.tags}
                      />
                    )
                  }
                </div>
              </section>
          ))
        }

      </main>

  </div>
  )
}
