import React from 'react'
import './Surface.css'

export const Surface = ({
  horizontal,
  bordered,
  variant = 'primary',
  className = '',
  style = {},
  as: Component = 'div',
  ...props
}) => (
  <Component
    className={`
      ossy-surface
      ${horizontal ? 'ossy-surface--horizontal' : 'ossy-surface--vertical'}
      ${bordered ? 'ossy-surface--bordered' : ''}
      ${className}
    `}
    style={{
      background: `var(--surface-${variant}, var(--surface, inherit))`,
      color: `var(--on-surface-${variant}, var(--on-surface, inherit))`,
      boxShadow: `var(--surface-${variant}-box-shadow)`,
      ...style
    }}
    {...props}
  />
)

export const SurfaceItem = ({
  fill,
  as: Component = 'div',
  className = '',
  ...props
}) => (
  <Component
    className={`
      ossy-surface__item
      ${fill ? 'ossy-surface__item--fill' : ''}
      ${className}`
    }
    {...props}
  />
)

Surface.Item = SurfaceItem
