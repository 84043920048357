
const seNavigation = [
  { id: 'start-nav', label: 'Start', href: '/' },
  { id: 'services-nav', label: 'Tjänster', href: '#services' },
  { id: 'references-nav', label: 'Om oss', href: '#about' },
  { id: 'contact-nav', label: 'Kontakt', href: '#contact' }
]

const seContact = [
  { label: 'tepit.se', href: 'https://tepit.se/' },
  { label: 'kontakt@tepit.se', href: 'mailto:kontakt@tepit.se' }
]

export const Swedish = {

  StandardLayout: {
    logo: 'tepit',
    copyright: 'Copyright @ Tepit Consulting AB 2023',
    navigation: seNavigation,
    headerActions: [
      {
        key: 'contact-us',
        label: 'Kontakta oss',
        href: '#contact',
        variant: 'cta'
      }
    ],
    footerColumns: [
      {
        title: 'Tepit Consulting AB',
        body: `
          Trots att Tepit Consulting AB är en ny aktör inom IT- och systemutvecklingsbranschen,
          bär våra grundare med sig en rikedom av kunskap och erfarenhet som samlats över många år i branschen.
          Drivna av passion för teknik och innovation, är vi dedikerade till att erbjuda lösningar av högsta kvalitet.
          Med en stark tro på att rätt teknik kan förvandla affärslandskapet,
          är vi här för att bli din förlitade partner inom systemutveckling.
        `
      },
      {
        title: 'Navigation',
        list: seNavigation
      },
      {
        title: 'Kontakt',
        list: seContact
      }

    ]
  },

  LandingPage: {

    hero: {
      title: 'Konsulter du kan lita på',
      text: `
        Med vår expertis inom systemutveckling skapar vi med passion
        webbapplikationer som inte bara är tekniskt avancerade,
        utan också estetiskt tilltalande.
        När vår tekniska kunskap möter vår kärlek till design,
        skapar vi lösningar som verkligen lyfter ditt företags digitala närvaro.
      `,
      actions: [
        {
          key: 'contact-us',
          label: 'Kontakta oss',
          href: '#contact',
          variant: 'cta'
        }
      ]
    },

    services: [
      {
        title: 'Apputveckling',
        text: `
          Oavsett om du siktar på en responsiv hemsida eller komplex webbapplication,
          kan vi med vår expertis inom systemutveckling leverera lösningar
          som är kraftfulla, skalbara, användarvänliga, och estetiskt tilltalande.
          Vår erfarenhet täcker allt från initial konceptualisering till lansering och underhåll.
        `
      },
      {
        title: 'Designsystem & Komponentbibliotek',
        text: `
          I den digitala världen är igenkännbarhet nyckeln till en suverän användarupplevelse.
          Vi kan hjälpa dig med att implementera och integrera designsystem som säkerställer
          en enhetlig och igenkännbar stil över alla dina digitala gränssnitt.
          Tillsammans bygger vi komponentbibliotek som gör det snabbt och enkelt att skapa nya sidor och funktioner,
          samtidigt som vi behåller den höga designstandarden.
          Låt oss tillsammans skapa digitala upplevelser som dina användare kommer att älska.
        `
      }
    ],

    about: {
      maxWidth: 'large',
      columns: [
        {
          name: 'Jimmy Lord',
          role: 'VD',
          imgSrc: 'https://d1yuixo7x29bj4.cloudfront.net/0MAhbDLStZ68NrW3HGwLa/17LAxYft6hsgUaJXKtnT3.jpeg'
        },
        {
          name: 'Viktor Hansen',
          role: 'CFO',
          imgSrc: 'https://d1yuixo7x29bj4.cloudfront.net/0MAhbDLStZ68NrW3HGwLa/LvuMmCWyv1c9TKglJbBxJ.jpeg'
        },
        {
          name: 'Oskars Sylwan',
          role: 'CTO',
          imgSrc: 'https://d1yuixo7x29bj4.cloudfront.net/0MAhbDLStZ68NrW3HGwLa/04KQ5FfCdTXdIKSSmqtEL.png'
        }
      ]
    },

    contact: {
      maxWidth: 'medium',
      surface: 'secondary',
      columns: [
        {
          title: 'Kontakta oss',
          body: `
            Vi ser fram emot att höra från dig och diskutera hur vi kan samarbeta på ditt nästa projekt.
            Det ät lättast att nå oss via mail.
            Tveka inte att ta kontakt!
          `
        },
        {
          list: seContact
        }
      ]
    }

  }

}
