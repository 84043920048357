import React, { useMemo } from 'react'
import { css } from 'glamor'
import { Title, Button, Text } from '../atoms'


export const Footer = ({
  columns = [],
  gap = 'm',
}) => {

  const styles = useMemo(() => css({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: `var(--space-${gap})`,
    padding: 'var(--space-s)',

    '& .title': {
      marginBottom: 'var(--space-m)'
    },

    '& .list': {
      padding: '0'
    },

    '& .list-item': {
      listStyle: 'none'
    }
  })
  ,[columns])

  return (
    <div {...styles}>

      {
        columns.map(column => (
          <div style={{
            flexBasis: `calc(calc(100% / ${columns.length}) - var(--space-${gap}))`,
            display: 'flex',
            justifyContent: 'center'
          }}>
            <div>
              <Title variant="secondary" className="title">{column.title}</Title>
              {
                !!column.body && <Text>{column.body}</Text>
              }
              {
                !!column.list && (
                  <ul className="list" key={column.title}>
                    {
                      column.list.map(({ label, href, ...anchorProps }) => (
                        <li className="list-item" key={label}>
                          <Button variant="link" as={!!href ? 'a' : 'span'} href={href} {...anchorProps}>{label}</Button>
                        </li>
                      ))
                    }
                  </ul>
                )
              }
            </div>
          </div>

        ))
      }
    </div>
  )
}
